import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LocationService = /** @class */ (function () {
    function LocationService(http) {
        this.http = http;
        this.API_URL = environment.API_URL;
    }
    LocationService.prototype.getLocations = function (providerId) {
        return this.http.get(this.API_URL + "api/providers/" + providerId + "/locations");
    };
    LocationService.ngInjectableDef = i0.defineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.inject(i1.HttpClient)); }, token: LocationService, providedIn: "root" });
    return LocationService;
}());
export { LocationService };
