import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MonitorService = /** @class */ (function () {
    function MonitorService(http) {
        this.http = http;
        this.API_URL_V2 = environment.API_URL_V2;
    }
    MonitorService.prototype.getLocationsStatus = function (providerId) {
        var _this = this;
        return new Observable(function (obs) {
            _this.http.get(_this.API_URL_V2 + "insights/providers/" + providerId + "/locations-status")
                .subscribe(function (response) {
                obs.next(_this.buildLocationsList(response));
                obs.complete();
            });
        });
    };
    MonitorService.prototype.buildLocationsList = function (locations) {
        var _this = this;
        var lcts = [];
        lcts = locations.map(function (loc) { return _this.buildLocation(loc); });
        return lcts;
    };
    MonitorService.prototype.buildLocation = function (data) {
        switch (data.status) {
            case 0:
                data.status = 'grey';
                break;
            case 1:
                data.status = 'orange';
                break;
            case 2:
                data.status = 'red';
                break;
            case 3:
                data.status = 'green';
        }
        return data;
    };
    MonitorService.ngInjectableDef = i0.defineInjectable({ factory: function MonitorService_Factory() { return new MonitorService(i0.inject(i1.HttpClient)); }, token: MonitorService, providedIn: "root" });
    return MonitorService;
}());
export { MonitorService };
