import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EventService } from '../../shared/services/event.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(_http) {
        this._http = _http;
        this.API_URL = environment.API_URL;
        this.authentication = { isAuth: false, userName: '' };
        this.externalAuthData = { externalAccessToken: '', provider: '', userName: '' };
        this.headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
    }
    AuthService.prototype.isAuth = function () {
        var currentUser = JSON.parse(localStorage.getItem('authorizationData'));
        if (currentUser && currentUser.token) {
            return true;
        }
        return false;
    };
    AuthService.prototype.logOut = function () {
        localStorage.removeItem('authorizationData');
        this.authentication.isAuth = false;
        this.authentication.userName = '';
    };
    AuthService.prototype.getToken = function (loginData) {
        var _this = this;
        var httpOptions = {
            headers: this.headers
        };
        return new Observable(function (observer) {
            _this._http.post(_this.API_URL + "token", loginData, httpOptions)
                .subscribe(function (response) {
                var authorizationData = {
                    refreshToken: response.refresh_token,
                    token: response.access_token,
                    userName: response.userName
                };
                localStorage.setItem('authorizationData', JSON.stringify(authorizationData));
                _this.authentication.isAuth = true;
                _this.authentication.userName = loginData.userName;
                _this.afterLogin()
                    .subscribe();
                observer.next(response);
                observer.complete();
            }, function (error) { return observer.error(error); });
        });
    };
    AuthService.prototype.login = function (loginData) {
        var body = "client_id=" + environment.CLIENT_ID + "&grant_type=password&password=" + loginData.password + "&userName=" + loginData.userName;
        return this.getToken(body);
    };
    AuthService.prototype.refreshToken = function () {
        var _this = this;
        var authData = JSON.parse(localStorage.getItem('authorizationData'));
        return new Observable(function (observer) {
            if (authData && authData.refreshToken) {
                var token = authData.refreshToken;
                var clientId = environment.CLIENT_ID;
                var data = "grant_type=refresh_token&refresh_token=" + token + "&client_id=" + clientId;
                localStorage.removeItem('authorizationData');
                _this._http.post(_this.API_URL + "token", data, { headers: _this.headers })
                    .subscribe(function (response) {
                    localStorage.setItem('authorizationData', JSON.stringify({
                        refreshToken: response.refresh_token,
                        token: response.access_token,
                        userName: response.userName
                    }));
                    _this.afterLogin()
                        .subscribe();
                    observer.next(response);
                    observer.complete();
                }, function (error) { return observer.error(error); });
            }
            else {
                observer.complete();
            }
        });
    };
    AuthService.prototype.validateEmail = function (data) {
        return this._http.post(this.API_URL + "api/account/confirm", data);
    };
    AuthService.prototype.afterLogin = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this._http.get(_this.API_URL + "api/me/profile")
                .subscribe(function (response) {
                var localStorageData = JSON.parse(localStorage.getItem('authorizationData'));
                var userData = response.user;
                userData.token = localStorageData.token;
                userData.userName = localStorageData.userName;
                if (localStorageData.refreshToken) {
                    userData.refreshToken = localStorageData.refreshToken;
                }
                localStorage.setItem('authorizationData', JSON.stringify(userData));
                EventService.afterLogin.emit();
                observer.next(response);
                observer.complete();
            }, function (error) { return observer.error(error); });
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
