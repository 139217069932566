import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var EventService = /** @class */ (function () {
    function EventService() {
    }
    EventService.changeTitle = new EventEmitter();
    /* Eventos de Login */
    EventService.afterLogin = new EventEmitter();
    EventService.loginModal = new EventEmitter();
    EventService.loadResources = new EventEmitter();
    EventService.ngInjectableDef = i0.defineInjectable({ factory: function EventService_Factory() { return new EventService(); }, token: EventService, providedIn: "root" });
    return EventService;
}());
export { EventService };
