import * as i0 from "@angular/core";
var UserPreferencesService = /** @class */ (function () {
    function UserPreferencesService() {
    }
    UserPreferencesService.prototype.getInformation = function () {
        return JSON.parse(localStorage.getItem('user-preferences')) || {};
    };
    UserPreferencesService.prototype.get = function (key) {
        var currentUserPreferences = this.getInformation();
        return currentUserPreferences[key];
    };
    UserPreferencesService.prototype.set = function (key, value) {
        var userPreferences = this.getInformation();
        userPreferences[key] = value;
        localStorage.setItem('user-preferences', JSON.stringify(userPreferences));
    };
    UserPreferencesService.prototype.clearValue = function (key) {
        var userPreferences = this.getInformation();
        userPreferences[key] = undefined;
        localStorage.setItem('user-preferences', JSON.stringify(userPreferences));
    };
    UserPreferencesService.ngInjectableDef = i0.defineInjectable({ factory: function UserPreferencesService_Factory() { return new UserPreferencesService(); }, token: UserPreferencesService, providedIn: "root" });
    return UserPreferencesService;
}());
export { UserPreferencesService };
