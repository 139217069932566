/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./monitoring-panels.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./monitoring-card/monitoring-card.component.ngfactory";
import * as i3 from "./monitoring-card/monitoring-card.component";
import * as i4 from "@angular/common";
import * as i5 from "./monitoring-panels.component";
import * as i6 from "../../shared/services/user-preferences.service";
import * as i7 from "../../shared/services/monitor.service";
var styles_MonitoringPanelsComponent = [i0.styles];
var RenderType_MonitoringPanelsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MonitoringPanelsComponent, data: {} });
export { RenderType_MonitoringPanelsComponent as RenderType_MonitoringPanelsComponent };
function View_MonitoringPanelsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "spinner"]], null, null, null, null, null))], null, null); }
function View_MonitoringPanelsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-monitoring-card", [], null, null, null, i2.View_MonitoringCardComponent_0, i2.RenderType_MonitoringCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.MonitoringCardComponent, [], { location: [0, "location"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MonitoringPanelsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "article", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "grid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MonitoringPanelsComponent_3)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.provider.locations; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_MonitoringPanelsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "title-section"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MonitoringPanelsComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MonitoringPanelsComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoading; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.isLoading; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.provider.name; _ck(_v, 3, 0, currVal_0); }); }
export function View_MonitoringPanelsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-monitor", [], null, null, null, View_MonitoringPanelsComponent_0, RenderType_MonitoringPanelsComponent)), i1.ɵdid(1, 114688, null, 0, i5.MonitoringPanelsComponent, [i6.UserPreferencesService, i7.MonitorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MonitoringPanelsComponentNgFactory = i1.ɵccf("app-monitor", i5.MonitoringPanelsComponent, View_MonitoringPanelsComponent_Host_0, {}, {}, []);
export { MonitoringPanelsComponentNgFactory as MonitoringPanelsComponentNgFactory };
