import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProvidersService = /** @class */ (function () {
    function ProvidersService(_http) {
        this._http = _http;
        this.API_URL = environment.API_URL;
    }
    ProvidersService.prototype.getProviders = function () {
        return this._http.get(this.API_URL + "api/me/providers");
    };
    ProvidersService.ngInjectableDef = i0.defineInjectable({ factory: function ProvidersService_Factory() { return new ProvidersService(i0.inject(i1.HttpClient)); }, token: ProvidersService, providedIn: "root" });
    return ProvidersService;
}());
export { ProvidersService };
